import { Component, Input } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'switch-page',
  templateUrl: './switch-page.component.html',
})
export class SwitchPageComponent {
  @Input() currentPage: number = 1;
  @Input() linesPerPage: number = 50;
  @Input() totalCount: number = 0;
  @Input() isShow : boolean = false;
  @Output() switchPage = new EventEmitter<string>();

  totalPage = () => {
    return Math.ceil(this.totalCount / this.linesPerPage);
  }
  pageList = () => {
    const pageList = [];
    for (let i = 0; i < this.totalPage(); i++) pageList.push(i + 1);
    return pageList;
  }

  hasPreviousPage = () => this.currentPage - 1 >= 1;
  hasNextPage = () => this.currentPage < this.totalPage();
  hasFirstPage = () => this.currentPage > 1;
  hasLastPage = () => this.currentPage < this.totalPage();

  first() {
    if (this.currentPage == 1) return;
    this.currentPage = 1;
    this.pageTo();
  }
  previous() {
    if (this.currentPage <= 1) return;
    this.currentPage--;
    this.pageTo();
  }
  next() {
    if (this.currentPage >= this.totalPage()) return;
    this.currentPage++;
    this.pageTo();
  }
  last() {
    if (this.currentPage == this.totalPage()) return;
    this.currentPage = this.totalPage();
    this.pageTo();
  }
  pageTo() {
    this.switchPage.emit(this.currentPage.toString());
  }
}

<loading-component *ngIf="!isFinishedPrepare" />
<loading-component *ngIf="isLoading" />
<main>
  <mamezo-message [messageData]="headerMessage" />
  <div class="dev-pc div-main">
    <ng-container *ngIf="isFinishedPrepare">
      <form class="ng-pristine ng-valid ng-touched ng-submitted">
        <div pcwidthtype="min" class="dev-pc div-card pc-min-width">
          <div class="dev-pc div-card-body row"  style="margin: 0;">
            <div id="searchConditionHeader" class="dev-pc div-card-title col-auto row" style="margin: auto 0;">
              <div class="d-flex flex-row col-auto div-has-tooltip" style="padding-left:0;">
                <span>あなたが設定可能な拠点</span>
                <div class="div-tooltip tooltip-bottom" title="あなたのユーザーIDに紐づけられた拠点です。" style="margin-top: 4px; margin-left: 3px;"></div>
              </div>
              <div class="col-auto" style="color:#2967ac;font-size:20px;">
                全{{ kyotenCount < 0 ? ' - ' : kyotenCount }}件
              </div>
            </div>
            <div class="me-lg-auto col-auto">
              <button class="ms-lg-2 dev-pc btn btn-lg btn-negative" style="margin:0;" (click)="onDefaultButtonClicked()" [disabled]="kyotenCount < 1">表示</button>
            </div>
          </div>
        </div>
      </form>

      <!-- 検索条件 -->
      <search-form
        #search_form
        [searchForm]="searchForm"
        [mailGroupChoiceList]="mailGroupChoiceList"
        [keiyakuHojinList]="keiyakuHojinList"
        [kyotenList]="kyotenList"
        [roleList]="roleList"
        [displayColConfig]="displayColConfig"
        (doSearch)="onSearchButtonClicked()"
        (clear)="onClearButtonClicked()"
      />
    </ng-container>

    <!-- 検索結果 -->
    <search-result
      [isShow]="isSearchResult() ?? false"
      [searchForm]="searchForm"
      [searchResult]="searchResult"
      [mailConfigForm]="mailConfigForm"
      [displayColConfig]="displayColConfig"
      [configColCheck]="configColCheck"
      [omitSubmit]="isSubmitting"
      (clickMailGroupDetailModal)="onclickMailGroupDetailModal($event.description, $event.clickEvent)"
      (clickRegisterButton)="registerConfirms.open()"
      (doSearchFromResult)="doSearchFromResult($event)"
    />
  </div>

  <!-- モーダル -->
  <confirm-modal modalTitle="登録確認" [isShow]="registerConfirms.isOpen"
    leftLabel="閉じる" (clickLeft)="registerConfirms.clickLeft()"
    rightLabel="登録" (clickRight)="registerConfirms.clickRight()"
    [rightButtonDisabled]="isSubmitting">
    <p>チェックがあるメール受信区分のメールを受信します。よろしいですか？</p>
  </confirm-modal>
  <confirm-modal modalTitle="登録完了" message="" [isShow]="registerComplete.isOpen"
    rightLabel="OK" (clickRight)="registerComplete.clickRight()">
    <p>登録が完了しました。</p>
  </confirm-modal>
  <confirm-modal modalTitle="問い合わせ" message="" [isShow]="registerBeforeSearchConfirm.isOpen"
    leftLabel="キャンセル" (clickLeft)="registerBeforeSearchConfirm.clickLeft()"
    rightLabel="OK" (clickRight)="registerBeforeSearchConfirm.clickRight()">
    <p>編集中の内容は失われます。よろしいですか？[XMD00004]</p>
  </confirm-modal>
  <confirm-modal modalTitle="登録完了" message="" [isShow]="registerBeforeSearchComplete.isOpen"
    rightLabel="OK" (clickRight)="registerBeforeSearchComplete.clickRight()">
    <p>登録が完了しました。</p>
  </confirm-modal>
  <confirm-modal modalTitle="問い合わせ" [isShow]="registerBeforeClearConfirm.isOpen"
    leftLabel="キャンセル" (clickLeft)="registerBeforeClearConfirm.clickLeft()"
    rightLabel="OK" (clickRight)="registerBeforeClearConfirm.clickRight()">
    <p>編集中の内容は失われます。よろしいですか？[XMD00004]</p>
  </confirm-modal>
  <confirm-modal modalTitle="登録完了" message="" [isShow]="registerBeforeClearComplete.isOpen"
    rightLabel="OK" (clickRight)="registerBeforeClearComplete.clickRight()">
    <p>登録が完了しました。</p>
  </confirm-modal>
  <simple-modal [isShow]="isMailGroupDetailModal" (clickClose)="closeMailGroupDetailModal()">
    <div>{{ mailGroupDetail }}</div>
  </simple-modal>

</main>

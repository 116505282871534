import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './page/errors/notfound/notfound.component';
import { InternalServerErrorComponent } from './page/errors/servererror/servererror.component';
import { authGuard } from './auth/auth.guard';
import { UnauthorizedComponent } from './page/errors/unauthorized/unauthorized.component';
import { ServiceUnavailableComponent } from './page/errors/serviceunavailable/service-unavailable.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./page/toppage/toppage.module').then((m) => m.TopPageModule),
    canActivate: [authGuard],
  },

  { path: '401', component: UnauthorizedComponent, },
  { path: '404', component: NotFoundComponent, },
  { path: '500', component: InternalServerErrorComponent, },
  { path: '503', component: ServiceUnavailableComponent, },
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled', // enabled で有効化
      anchorScrolling: 'enabled', // enabled で有効化
      scrollOffset: [0, 10], // [x, y] 設定：オフセットを使用する場合のみ記載
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

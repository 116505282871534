import { AuthConfig } from '@auth0/auth0-angular';
import { EnvironmentType } from './environment-type';

const AUTH0_DOMAIN = 'pclogin-e2e.jpr.jp';
const AUTH0_CLIENT_ID = 'dq7BvNSHB4dS3X6W20QsbzuBVz6FkK8i';
const REDIRECT_URI = 'https://xmd.e2e-x-rental.jp/';

export const environment: EnvironmentType = {
  TMP_TOKEN: undefined,
  production: true,
  DEV: false, // trueの場合、http環境想定でangular-moduleを使用しない
  ENV_NAME: 'E2E',
  // バックエンド BASE URL
  BACKEND_BASE_URL: 'https://apibf.xmd.e2e-x-rental.jp/',
  // AUTH0_CLIENT_AUDIENCE: 'https://bf.xmd.e2e-x-rental.jp/api/v1/',
  AUTH0_CONFIG: {
    domain: AUTH0_DOMAIN,
    clientId: AUTH0_CLIENT_ID,
    useRefreshTokens: true,
    authorizationParams: {
      redirect_uri: REDIRECT_URI,
      audience: 'https://bf.xmd.e2e-x-rental.jp/api/v1/',
      scope: 'openid profile email offline_access',
},
  } as AuthConfig,
  logger: {
    log: (...args: any[]) => console.log(...args),
  }
};

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'simple-modal',
  templateUrl: './simple-modal.component.html',
  styleUrls: ['./simple-modal.component.scss'],
})
export class SimpleModalComponent {
  @Input() isShow : boolean = false;
  @Output() clickClose = new EventEmitter();

  onclickClose() {
    this.clickClose.emit();
  }
}


<div class="toppage-wrapper" [attr.style]="isShow ? 'display: block' : 'display: none;'">
  <div class="ng-untouched ng-pristine ng-valid" *ngIf="searchResult">
    <div class="dev-pc div-card pc-min-width">
      <div class="dev-pc div-card-body">
        <!-- 検索結果件数・表示件数 -->
        <div class="dev-pc div-card-title result-title">
          <div class="row justify-content-between d-flex align-items-center">
            <div class="col-auto row d-flex align-items-center">
              <span class="col-auto">検索結果</span>
              <span class="col-auto gray cnt-font">全{{ searchResult.kyotens.length ? searchResult.kyotens[0].result_count : 0 }}件</span>
            </div>
            <div class="col-auto row d-flex align-items-center">
              <div class="col-auto">
                <span class="cnt-font">表示件数</span>
              </div>
              <div class="col-auto">
                <form [formGroup]="searchForm">
                  <ng-container  formGroupName="page">
                    <select formControlName="linesPerPage" id="paginationInput" class="ng-untouched ng-pristine ng-valid dev-pc form-select"
                      (change)="onLinesPerPageChanged()">
                      <ng-container *ngFor="let obj of [10, 30, 50, 100, 200]">
                        <option [value]="obj">{{ obj }}</option>
                      </ng-container>
                    </select>
                  </ng-container>
                </form>
              </div>
            </div>
          </div>
        </div>
        <hr class="title-hr">
        <!-- 登録ボタン -->
        <div class="dev-pc div-card-body-sub">
          <div class="d-flex flex-column flex-lg-row">
            <div class="order-lg-1 me-lg-auto d-flex flex-column flex-lg-row">
              <div class="order-lg-1 me-lg-2 listHeader">
                <button class="dev-pc btn btn-lg btn-standard customModal" data-target="dialogModal_btn"
                  style="margin-right: 0.5em;"
                  (click)="clickRegisterButton.emit()" [disabled]="isRegisterButtonDisabled()">登録</button>
                
                <div style="display: inline-block;">
                  <a class="" style="color: #2967ac; cursor: pointer; font-weight: bold; text-decoration: underline;"
                  (click)="clickMailGroupDetailModal.emit({description: registerButtonDescription, clickEvent: $event})">登録時の注意</a>
                </div>
                <!-- <button type="button" data-target="customModal_btn" class="btn btn-info btn-sm button-custom dev-pc btn-standard customModal"
                  title="クリックで○○を表示します。"
                  (click)="clickMailGroupDetailModal.emit({description: '内容', clickEvent: $event})">
                  <i class="bi bi-question"></i>
                </button> -->
              </div>
            </div>
          </div>
        </div>
        <div class="dev-pc div-card-body-sub">
          <div id="search_result_div" class="table-area base-table-area">
            <!-- 検索結果テーブル -->
            <table class="custom-table table table-striped table-hover base-table">
              <colgroup>
                <col style="width: 40px;">
                <col style="width: 260px;">
                <col style="width: 200px;" [class.omit-col]="!displayColConfig.jusho">
                <col style="width: 100px;" [class.omit-col]="!displayColConfig.tantosha">
                <ng-container *ngFor="let msystem of searchResult.msystem_mail_groups">
                  <col style="width: 165px;" [class.omit-col]="!displayColConfig.role">
                </ng-container>
                <col style="width: 100px;" [class.omit-col]="!displayColConfig.lastUpdated">
                <ng-container *ngFor="let msystem of searchResult.msystem_mail_groups">
                  <ng-container *ngFor="let mailGroup of msystem.mail_groups">
                    <col style="min-width: 120px;">
                  </ng-container>
                </ng-container>
              </colgroup>
              <thead>
                <tr>
                  <th class="" [attr.colspan]="displayColConfigCols() + 2"></th>
                  <ng-container *ngFor="let msystem of searchResult.msystem_mail_groups">
                    <th [attr.colspan]="msystem.mail_groups.length">{{ msystem.msystem_name }}</th>
                  </ng-container>
                </tr>
                <tr>
                  <th class="" [attr.colspan]="displayColConfigCols() + 2"></th>
                  <ng-container *ngFor="let msystem of searchResult.msystem_mail_groups">
                    <ng-container *ngFor="let mailGroup of msystem.mail_groups">
                      <th class="all-check" [attr.data-title]="msystem.msystem_name" [attr.data-label]="mailGroup.name">
                        <input type="checkbox"
                          [(ngModel)]="configColCheck['m' + mailGroup.id].checked"
                          (change)="oncheckMailConfigCol('m' + mailGroup.id)">
                      </th>
                    </ng-container>
                  </ng-container>
                </tr>
                <tr>
                  <th rowspan="2" class="text-center sticky-left" role="" style="left: 0px; z-index: 10;">変更</th>
                  <th role="button" class="text-decoration-underline sticky-left" style="left: 40px; z-index: 10;" rowspan="2"
                    (click)="changeSort('CODE')"
                    [attr.sorted]="getSortedAttr('CODE')">コード／名称</th>
                  <th rowspan="2" role="button" class="border-bottom text-decoration-underline"
                    (click)="changeSort('JUSHO')"
                    [class.omit-col]="!displayColConfig.jusho"
                    [attr.sorted]="getSortedAttr('JUSHO')">住所</th>
                  <th rowspan="2" role="button" class="border-bottom text-decoration-underline"
                    (click)="changeSort('TANTOSHA')"
                    [class.omit-col]="!displayColConfig.tantosha"
                    [attr.sorted]="getSortedAttr('TANTOSHA')">拠点担当者</th>
                  <th [attr.colspan]="searchResult.msystem_mail_groups.length"
                    [class.omit-col]="!displayColConfig.role">ロール</th>
                  <th rowspan="2" role="button" class="border-bottom text-decoration-underline"
                    [class.omit-col]="!displayColConfig.lastUpdated"
                    (click)="changeSort('LAST_UPDATED')"
                    [attr.sorted]="getSortedAttr('LAST_UPDATED')">最終更新日</th>
                  <ng-container *ngFor="let msystem of searchResult.msystem_mail_groups">
                    <ng-container *ngFor="let mailGroup of msystem.mail_groups">
                      <th rowspan="2" role="button" class="text-decoration-underline"
                        (click)="changeSort('MAIL_GROUP', mailGroup.id)"
                        [attr.sorted]="getSortedAttr('MAIL_GROUP', mailGroup.id)">{{ mailGroup.name }}
                        <button type="button" data-target="customModal_btn" class="btn btn-info btn-sm button-custom dev-pc btn-standard customModal" title="クリックでメールの種類を表示します。"
                          (click)="clickMailGroupDetailModal.emit({description: mailGroup.description, clickEvent: $event})">
                          <i class="bi bi-question"></i>
                        </button>
                      </th>
                    </ng-container>
                  </ng-container>
                </tr>
                <tr>
                  <ng-container *ngFor="let msystem of searchResult.msystem_mail_groups">
                    <th role="button" class="border-bottom text-decoration-underline"
                      (click)="changeSort('ROLE', msystem.mz_msystem_id)"
                      [class.omit-col]="!displayColConfig.role"
                      [attr.sorted]="getSortedAttr('ROLE', msystem.mz_msystem_id)">{{ msystem.msystem_name }}</th>
                  </ng-container>
                </tr>
              </thead>

              <ng-container *ngFor="let result of searchResult.kyotens">
                <tbody class="ng-untouched ng-pristine ng-valid">
                  <tr class="ng-untouched ng-pristine ng-valid" [class.upr]="result.is_upr_kyoten">
                    <td scope="row" class="text-center sticky-left is-item" data-label="変更あり">
                      <input type="checkbox" name="0-selectFlg" class="ng-untouched ng-pristine ng-valid dev-pc selectFlg form-check-input table-input table-input-checkbox"
                        [checked]="this.mailConfigForm[result.kyoten_cd].is_updated" [ariaChecked]="this.mailConfigForm[result.kyoten_cd].is_updated">
                    </td>
                    <!-- prettier-ignore -->
                    <td class="inner-table sticky-left02 is-item" data-title="コード／名称" title="{{ result.x_keiyaku_hojin_cd }}
{{ result.keiyaku_hojin_nm }}
{{ result.kyoten_cd }}
{{ result.kyoten_long_nm }}"><div class="addClass-block">{{ result.kyoten_cd }}<br>{{ result.kyoten_nm }}</div>
                    </td>
                    <td class="inner-table is-item" data-title="住所" title="{{result.kyoten_jusho}}"
                      [class.omit-col]="!displayColConfig.jusho">
                      <div class="addClass-block">{{ result.kyoten_jusho }}</div>
                    </td>
                    <td class="inner-table is-item" data-title="担当者" title="{{ result.kyoten_tantosha_nm }}"
                      [class.omit-col]="!displayColConfig.tantosha">
                      <div class="addClass-block">{{ result.kyoten_tantosha_nm }}</div>
                    </td>
                    <ng-container *ngFor="let msystem of searchResult.msystem_mail_groups">
                      <td class="inner-table is-item" data-title="ロール" [attr.data-label]="'（'+msystem.msystem_name+'）'"
                        style="white-space: pre-wrap;"
                        [class.omit-col]="!displayColConfig.role">{{ result.system_roles["s" + msystem.mz_msystem_id] }}</td>
                    </ng-container>
                    <td class="inner-table is-item" data-title="最終更新日"
                      [class.omit-col]="!displayColConfig.lastUpdated"
                      >{{ dateToStr(searchResult.mail_configs[result.kyoten_cd].last_update) }}
                    </td>
                    <ng-container *ngFor="let msystem of searchResult.msystem_mail_groups">
                      <ng-container *ngFor="let mailGroup of msystem.mail_groups">
                        <ng-template [ngIf]="mailConfigForm[result.kyoten_cd].configs['m' + mailGroup.id] && mailConfigForm[result.kyoten_cd].configs['m' + mailGroup.id].is_configurable" [ngIfElse]="noConfig">
                          <td class="check-cell" [attr.data-title]="msystem.msystem_name">
                            <div class="check-cell__inner">
                              <label class="check-cell__ceckbox"><input type="checkbox"
                                [formControl]="mailConfigForm[result.kyoten_cd].configs['m' + mailGroup.id].subscribe"
                                (change)="onchangeConfig(result.kyoten_cd)"
                                ><span>{{ mailGroup.name }}</span></label>
                            </div>
                          </td>
                        </ng-template>
                        <ng-template #noConfig>
                          <td class="check-cell is-disabled" [attr.data-title]="msystem.msystem_name">
                            <div class="check-cell__inner">
                              <label class="check-cell__ceckbox">-<span>{{ mailGroup.name }}</span></label>
                            </div>
                          </td>
                        </ng-template>
                      </ng-container>
                    </ng-container>
                  </tr>
                </tbody>
              </ng-container>
            </table>
          </div>
        </div>
        <!-- ページャー -->
        <div class="d-flex justify-content-center pagination" id="search_result_pager">
          <form [formGroup]="searchForm">
            <switch-page
              [currentPage]="searchForm.controls.page.value.pageTo"
              [linesPerPage]="searchForm.controls.page.value.linesPerPage"
              [totalCount]="searchResult.kyotens.length ? searchResult.kyotens[0].result_count : 0"
              (switchPage)="switchPage($event)"
            ></switch-page>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
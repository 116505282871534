<div id="customModal_btn" class="modal" [attr.style]="isShow ? 'display: block;' : ''">
  <div class="modal-inner">
  <div class="modal-content">
    <div class="modal-header">
      <span class="modalClose" (click)="onclickClose()">×</span>
    </div>
    <div class="modal-body">
      <p class="modal-message">
        <ng-content></ng-content>
      </p>
    </div>
    </div>
  </div>
</div>

<div class="dev-pc" *ngIf="messageData?.isShow ?? false">
  <div class="mt-2">
    <div class="dev-pc">
      <div xfwdiv="card" pcwidthtype="min" role="alert" class="xfw-alert p-0 rounded-0 mx-3 dev-pc div-card pc-min-width" [class.xfw-alert-success]="messageData?.type == 'success'">
        <div xfwdiv="card-body" class="dev-pc div-card-body">
          <div xfwdiv="card-body-sub" class="dev-pc div-card-body-sub">
            <div class="d-flex flex-row xfw-text-success">
              <span>・</span>
              <span>{{ messageData?.message }}{{ getCodeString() }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dev-pc"></div>
  </div>
</div>

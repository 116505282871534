import { Component, OnInit, inject } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { filter } from 'rxjs';
import { ComponentInteractionService } from './common/component-interaction.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  token: string | null = 'dumy';
  isAuthenticated: boolean = false;
  isError: boolean = false;
  localWindow: Window = window;
  isShowManualOrAssist: boolean = false;
  isShowSideMenuToggleButton: boolean = false;
  currentURL: string | null = null;
  envName = environment.ENV_NAME;

  router: Router = inject(Router);
  interactionService: ComponentInteractionService = inject(ComponentInteractionService);

  ngOnInit() {
    this.router.events.pipe(filter((e) => e instanceof NavigationStart)).subscribe((e: any) => {
      this.currentURL = e.url;
    });

    // 500エラーページでリロードした際はAPIコールさせない
    if (this.currentURL === '/500') {
      this.isError = true;
      return;
    }
  }

  logout = () => {
    // this.authService.logout();
  };
}

import { FormControl } from '@angular/forms'
import { environment } from 'projects/mamezo/src/environments/environment';

const logger = environment.logger;

export class MamezoMessage {
  private _isShow: boolean;
  public message : string = "";
  public code : string = "";
  public type : 'success' = 'success';
  constructor() {
    this._isShow = false;
  }
  setMessage(message: string) {
    this.message = message;
    return this;
  }
  setCode(code: string) {
    this.code = code;
    return this;
  }
  get isShow() {
    return this._isShow;
  }
  public show() {
    logger.log("header message show");
    this._isShow = true;
  }
  public hide() {
    logger.log("header message hide");
    this._isShow = false;
  }
}
type MailGroupChoiceKey = 'ANY' | 'NONE';
export class MailGroupChoice {
  constructor(
    public key: MailGroupChoiceKey | string,
    public name: string,
    public isGroup = false
  ) {}
}
const defaultChoices: MailGroupChoice[] = [
  new MailGroupChoice('ANY', 'いずれかに設定あり', true),
  new MailGroupChoice('NONE', 'いずれも設定なし', true),
];
export class MailGroupChoiceList {
  choiceMap: {
    [systemNm: string]: MailGroup[];
  } = {};
  systems: string[] = [];
  // メール受信区分を入れる
  public add(choice: MailGroup) {
    const systemNm = choice.msystem_name;
    if (!this.systems.find(system => system == systemNm)) {
      this.systems.push(systemNm);
    }
    if (!this.choiceMap.hasOwnProperty(systemNm)) {
      this.choiceMap[systemNm] = [];
    }
    if (!this.choiceMap[systemNm].find(c => c.mz_mail_group_id === choice.mz_mail_group_id)) {
      this.choiceMap[systemNm].push(choice);
    }
  }
  // select option 用に取得
  public getForOptions(): MailGroupChoice[] {
    let choices = [];
    choices.push(...defaultChoices);
    for (let i = 0; i < this.systems.length; i++) {
      let systemNm = this.systems[i];
      // システム全体の選択肢
      choices.push(
        new MailGroupChoice(
          '[' + this.choiceMap[systemNm].map((mailGroup) => mailGroup.mz_mail_group_id).join(',') + ']',
          systemNm,
          true
        )
      );
      // システムごとの選択肢
      this.choiceMap[systemNm].forEach((mailGroup) => {
        choices.push(
          new MailGroupChoice(String(mailGroup.mz_mail_group_id), mailGroup.name, false)
        );
      });
    }
    logger.log("getForOptions ", choices)
    return choices;
  }
}

type CodeNameType = {
  cd: string, nm: string, is_hide?: boolean
}
export type MailGroup = {
  mz_mail_group_id: number,
  mz_msystem_id: number,
  msystem_name: string,
  system_sort: number,
  name: string
};
export type KyotenListType = Array<{
  x_sso_cd: string,
  shiyo_kashi_kbn: "s" | "k",
  x_keiyaku_hojin_cd: string,
  keiyaku_hojin_nm: string,
  rental_jigyosha2_kaishi_ymd: string,
  kyoten_cd: string ,
  kyoten_nm: string,
  kyoten_long_nm: string,
  kyoten_jusho: string,
  kyoten_tantosha_nm?: string,
  x_shiyobasho_cd: string,
  x_kashidashisaki_cd: string,
  dempyokihyokyoka_kbn: string,
  is_upr_kyoten: boolean,
  system_roles: {
    [index: string]: string
  },
  result_count: number,
}>;
export type KeiyakuHojinListType = Array<CodeNameType>;
export type KyotenListT = Array<{
  cd: string,
  nm: string,
  is_hide?: boolean,
  x_keiyaku_hojin_cd: string,
}>;
export type SystemListType = Array<CodeNameType>;
export type RoleListType = Array<{
  role_id : string,
  role_nm : string,
  system_id : string,
  is_hide?: boolean,
}>;
/** 検索条件情報 */
export type SearchCondition = {
  mailGroups: Array<{
    mz_mail_group_id: number,
    mz_msystem_id: number,
    msystem_name: string,
    system_sort: number,
    name: string,
    default_send: boolean,
    revision: number
  }>,
  username : string,
  kyotenCount : number,
  keiyakuHojinList : KeiyakuHojinListType,
  kyotenList : KyotenListT,
  systemList : Array<CodeNameType>,
  roleList : RoleListType,
  isUprOnly: boolean,
  csrf?: string
};
/** 検索条件入力 */
export type SearchForm = {
  username: string,
  mailGroup: number[],
  keiyakuHojin: string,
  kyoten: string,
  kyotenName: string,
  systemId: string,
  kashidashisaki: string,
  jusho: string,
  roleId: string,
  kyotenTantosha: string,
  page: {
    pageTo: number,
    linesPerPage: number
  },
  sort: {
    key: 'CODE' | 'JUSHO' | 'TANTOSHA' | 'ROLE' | 'LAST_UPDATED' | 'MAIL_GROUP',
    /** key が ROLE の時の system_id、または key が MAIL_GROUP のときの mz_mail_group_id */
    subKey?: number,
    order: 'ASC' | 'DESC'
  },
};
/** 検索結果 メール受信区分一覧 */
export type MSystemMailGroupListType = Array<{
  mz_msystem_id: number,
  msystem_name: string,
  system_ids: Array<string>,
  mail_groups: Array<{
    id: number,
    name: string,
    default_send: boolean
    description: string,
  }>,
}>;
type MailGroupConfigType = {
  mz_mail_config_id?: number,
  mz_mail_group_id: number,
  subscribe: boolean,
  revision: number,
  is_configurable: boolean,
}
/** 検索結果 メール設定一覧 */
export type MailConfigListType = {
  [kyoten_cd: string] : { // ハイフンあり
    configs: Record<string, MailGroupConfigType>, // "m" + mail_group_id
    last_update?: string,
    is_updated: boolean,
  }
};
/** 検索結果 */
export type SearchResultType = {
  kyotens: KyotenListType,
  msystem_mail_groups: MSystemMailGroupListType,
  /** 注意：検索条件によらず、ユーザ user_name のすべてのメール受信設定が入っている */
  mail_configs: MailConfigListType,
};
/** メール受信設定フォーム（入力用） */
export type MailConfigInputForm = Record<string, { // kyoten_cd
    configs: Record<string, {// "m" + mz_mail_group_id
      mz_mail_config_id?: number,
      subscribe: FormControl<boolean | null>,
      original: boolean,
      revision: number,
      is_updated: boolean,
      /** 設定可能か。false の場合 "-" 表示 */
      is_configurable: boolean,
    }>,
    is_updated: boolean
  }
>;
/** メール受信設定フォーム（リクエスト用） */
export type MailConfigRequestForm = Array<{
  kyoten_cd: string,
  mz_mail_group_id: number,
  mz_mail_config_id?: number,
  subscribe: boolean,
  revision: number,
}>;
/** 契約法人検索ダイアログ 検索条件フォーム */
export type HojinSearchForm = {
  username?: string;
  keiyakuHojinCd?: string;
  keiyakuHojinNm?: string;
  keiyakuHojinKana?: string;
  yubinNo?: string;
  jusho?: string;
  linesPerPage?: number;
  pageTo?: number;
};
/** 契約法人検索ダイアログ 検索結果 */
export type HojinSearchResultType = Array<{
  x_keiyaku_hojin_cd: string;
  hojin_no: string;
  keiyaku_hojin_nm: string;
  keiyaku_hojin_long_nm: string;
  keiyaku_hojin_kana_nm: string;
  keiyaku_hojin_kana_long_nm: string;
  yubin_no: string;
  jusho: string;
  rental_jigyosha2_kaishi_ymd: Date;
  kankatsubunshitsu: string;

  result_count: number;
}>;
/** 拠点検索ダイアログ 検索条件フォーム */
export type KyotenSearchForm = {
  targetShiyobasho?: boolean,
  targetKashidashisaki?: boolean,
  keiyakuHojinCd?: string,
  kyotenCd?: string,
  kyotenNm?: string,
  kyotenKana?: string,
  yubinNo?: string,
  jusho?: string
  linesPerPage?: number,
  pageTo?: number,
}
/** 拠点検索ダイアログ 検索結果 */
export type KyotenSearchResultType = Array<{
  kyoten_cd: string,
  kyoten_nm: string,
  kyoten_long_nm: string,
  kyoten_kana: string,
  kyoten_long_kana: string,
  shubetsu: 's' | 'k', // s:「使用」、k:「貸出」
  yubin_no: string,
  kyoten_jusho: string,
  x_keiyaku_hojin_cd: string,
  keiyaku_hojin_nm: string,
  dempyokihyokyoka_kbn: string,
  result_count: number,
}>;

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SearchResultType, MailConfigInputForm } from '../toppage-types';
// import { environment } from 'projects/mamezo/src/environments/environment';

// const logger = environment.logger;

@Component({
  selector: 'search-result',
  templateUrl: './search-result.component.html',
})
export class SearchResultComponent {
  @Input() isShow: boolean = false;
  @Input() searchForm!: FormGroup;
  @Input() searchResult?: SearchResultType;
  @Input() mailConfigForm: MailConfigInputForm = {};
  @Input() displayColConfig = {jusho: true, tantosha: true, role: false, lastUpdated: true};
  @Input() configColCheck: Record<string, { checked: boolean }> = {};
  @Input() omitSubmit: boolean = false;
  @Output() clickMailGroupDetailModal = new EventEmitter();
  @Output() clickRegisterButton = new EventEmitter<void>();
  @Output() doSearchFromResult = new EventEmitter<void>();

  /** 数量なし伝票利用制限の mz_mail_group_id */
  MAIL_GROUP_SURYODENPYO_ID = 3;

  /** 検索結果表示項目の中で表示する列の数 */
  displayColConfigCols(): number {
    return (
      0 +
      (this.displayColConfig.jusho ? 1 : 0) +
      (this.displayColConfig.tantosha ? 1 : 0) +
      (this.displayColConfig.role ? this.searchResult?.msystem_mail_groups.length ?? 0 : 0) +
      (this.displayColConfig.lastUpdated ? 1 : 0)
    );
  }

  registerButtonDescription = `■ X-DDアラートについて
メール受信設定をオンにした場合でも、アラートが発生しなければメールは受信しません。
X-DD のアラートは X-DD 法人管理者ロールのユーザには発生しない仕様となっています。
拠点管理者ロールを付与すると、以降はアラートが表示されるようになり、メール受信設定が可能になります。
`;

  /**
   * kyoten_cd のいずれかのメール受信設定が変更されたときに呼ばれる
   * key: kyoten_cd
   */
  onchangeConfig(key: string) {
    Object.values(this.mailConfigForm[key].configs).forEach((config) => {
      config.is_updated = config.original !== config.subscribe.value;
    });
    this.mailConfigForm[key].is_updated = !Object.values(this.mailConfigForm[key].configs).every(
      (config) => config.original === config.subscribe.value
    );
  }
  /** 一括チェック時 */
  oncheckMailConfigCol(mailGroup: string) {
    for (let kyoten_cd in this.mailConfigForm) {
      // 存在チェック（一応）
      if (!this.mailConfigForm[kyoten_cd] || !this.mailConfigForm[kyoten_cd].configs[mailGroup]) {
        continue;
      }
      // 設定できない欄は対象外
      if (!this.mailConfigForm[kyoten_cd].configs[mailGroup].is_configurable) {
        continue;
      }
      this.mailConfigForm[kyoten_cd].configs[mailGroup].subscribe.setValue(
        this.configColCheck[mailGroup].checked
      );
      this.onchangeConfig(kyoten_cd);
    }
  }
  /** 登録ボタン Disabled 条件 */
  isRegisterButtonDisabled(): boolean {
    if (this.omitSubmit) return true;
    return !this._isAnyUpdated();
  }
  /** どれか1つでも更新あり？ */
  _isAnyUpdated(): boolean {
    return Object.values(this.mailConfigForm).some(mcf => mcf.is_updated);
  }
  /** 表示件数変更時 */
  onLinesPerPageChanged() {
    this.searchForm.controls.page.value.pageTo = 1;
    this.doSearchFromResult.emit(this.searchForm.value);
  }
  /** ページ番号変更時 */
  switchPage(value: string) {
    this.searchForm.controls.page.value.pageTo = value;
    this.doSearchFromResult.emit(this.searchForm.value);
  }
  /** ソート列クリック時（ソート順変更イベント起動） */
  changeSort(key: string, subKey?: number) {
    const currentValue = this.searchForm.controls.sort.value
    const diffKey = currentValue.key !== key || (currentValue.key === key && currentValue.subKey !== subKey)
    const notAsc = currentValue.order !== 'ASC'
    const newOrder = diffKey || notAsc ? 'ASC' : 'DESC';
    this.searchForm.controls.sort.value.key = key;
    this.searchForm.controls.sort.value.subKey = subKey;
    this.searchForm.controls.sort.value.order = newOrder;
    this.doSearchFromResult.emit(this.searchForm.value);
  }
  /**
   * 現在 key でソートしているかどうかを返す
   * @param key: ソートキー
   * @param subKey? : key が MAIL_GROUP の場合、mz_mail_group_id, ROLE の場合 msystem_id、それ以外の場合使用しない
   * @return ソートしている場合、"ASC" または "DESC"、ソートしていない場合 ""
   */
  getSortedAttr(key: string, subKey?: number): string {
    const currentValue = this.searchForm.controls.sort.value
    if (currentValue.key === key && (!subKey || currentValue.subKey === subKey)) {
      return currentValue.order;
    }
    return '';
  }
  dateToStr(d?: Date | string | null) : string {
    if (!d) return "";
    if (typeof d === "string") {
      const datePattern = /^(\d{4}\/\d{2}\/\d{2})/g;
      const result = d.match(datePattern);
      if (result) return result[0];
      return d;
    }
    const to2d = (src: number) => {
      return ("0" + src).slice(-2);
    }
    try {
      return d.getFullYear() + "/" + to2d(d.getMonth() + 1) + "/" + to2d(d.getDate());
    } catch(e) {
      console.warn("d is not Date: ", d);
      return "";
    }
  }
}
<div id="corporationModal_btn" class="modal" [attr.style]="isShow ? 'display: block;' : ''" (click)="onclickModalBackdrop()">
  <div class="modal-inner">
    <div class="modal-content" (click)="onclickModalContent($event)">
      <div class="modal-header">
        <h4 class="modal-title">拠点検索</h4>
        <div _ngcontent-ng-c3929693934="" id="close-button">
          <div _ngcontent-ng-c3929693934="" role="button" class="close" (click)="onclickClose()"><i _ngcontent-ng-c3929693934="" class="bi bi-x"></i></div>
        </div>
      </div>
      <div class="modal-body">
        <mamezo-message [messageData]="headerMessage" />
        <div id="dialogSearchConditionHeader" class="dev-pc div-card-title">
          <div class="row">
            <div class="d-flex flex-row">
              <span>検索条件</span>
              <button
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#dialogSearchCondition"
                [attr.aria-expanded]="isConditionOpen"
                aria-controls="dialogSearchCondition"
                class="dev-pc btn-accordion"
                id="corporationModalSearch_btn"
                (click)="onclickAccordion()"
              ></button>
            </div>
          </div>
        </div>
        <hr class="title-hr" />
        <div
          id="corporationModalSearch_detail"
          class="collapse show dev-pc div-card-body-sub search-section"
          [attr.style]="isConditionOpen ? 'display: block;' : 'display: none;'"
        >
          <form [formGroup]="searchForm" novalidate="" class="ng-touched ng-pristine ng-valid">
            <div class="container-fluid mb-2">
              <div class="row">
                <div class="col-lg-9">
                  <div class="row">
                    <!-- <div
                      required=""
                      class="col-lg-3 div-column-resize-plus dev-pc div-item-title"
                      style="padding-right: 17px"
                    > -->
                    <div
                      class="col-lg-3 div-column-resize-plus dev-pc div-item-title"
                      style="padding-right: 17px"
                    >
                      <label
                        class="col-lg-auto ng-untouched ng-pristine ng-valid dev-pc"> 検索対象</label>
                      <div class="div-item-title-required"></div>
                    </div>
                    <div class="col-lg-6 dev-pc div-item-input input-list-h">
                      <label
                        formarrayname="kensakuTaisho"
                        class="col-lg-auto ng-untouched ng-pristine ng-valid dev-pc"
                      >
                        <input
                          formControlName="targetShiyobasho"
                          type="checkbox"
                          value="1"
                          id="hyojiKensaku0"
                          name="0"
                          class="ng-untouched ng-pristine ng-valid dev-pc form-check-input"
                        />
                        <label class="me-3" for="hyojiKensaku0">
                          使用場所</label
                        ></label
                      ><label
                        formarrayname="kensakuTaisho"
                        class="col-lg-auto ng-untouched ng-pristine ng-valid dev-pc"
                      >
                        <input
                          formControlName="targetKashidashisaki"
                          type="checkbox"
                          value="2"
                          id="hyojiKensaku1"
                          name="1"
                          class="ng-untouched ng-pristine ng-valid dev-pc form-check-input"
                        />
                        <label class="me-3" for="hyojiKensaku1">
                          貸出先</label
                        ></label
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="col-lg-3 div-column-resize-plus dev-pc div-item-title"
                      style="padding-right: 0px"
                    >
                      <label>契約法人コード</label>
                      <div class="div-item-title-required"></div>
                      <div
                        class="div-tooltip"
                        title="契約法人コードを部分一致で検索します。"
                      ></div>
                    </div>
                    <div class="col-lg-3 dev-pc div-item-input">
                      <input
                        formControlName="keiyakuHojinCd"
                        id="keiyakuHojinCd"
                        type="text"
                        maxlength="16"
                        name="keiyakuHojinCd"
                        class="ng-untouched ng-pristine ng-valid dev-pc form-control"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="col-lg-3 div-column-resize-plus dev-pc div-item-title"
                      style="padding-right: 0px"
                    >
                      <label>拠点コード</label>
                      <div class="div-item-title-required"></div>
                      <div
                        class="div-tooltip"
                        title="拠点コードを部分一致で検索します。"
                      ></div>
                    </div>
                    <div class="col-lg-3 dev-pc div-item-input">
                      <input
                        formControlName="kyotenCd"
                        id="kyotenCd"
                        type="text"
                        maxlength="14"
                        name="kyotenCd"
                        class="ng-untouched ng-pristine ng-valid dev-pc form-control"
                        (blur)="onblurKyotenCode()"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="col-lg-3 div-column-resize-plus dev-pc div-item-title"
                      style="padding-right: 0px"
                    >
                      <label>拠点名称</label>
                      <div class="div-item-title-required"></div>
                      <div
                        class="div-tooltip"
                        title="使用場所名称、使用場所略称、貸出先名称、貸出先略称のいずれかに部分一致するものを検索します。"
                      ></div>
                    </div>
                    <div class="col-lg-5 dev-pc div-item-input">
                      <input
                        formControlName="kyotenNm"
                        id="kyotenNm"
                        type="text"
                        maxlength="80"
                        name="kyotenNm"
                        class="ng-untouched ng-pristine ng-valid dev-pc form-control"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="col-lg-3 div-column-resize-plus dev-pc div-item-title"
                      style="padding-right: 0px"
                    >
                      <label>拠点名称カナ</label>
                      <div class="div-item-title-required"></div>
                      <div
                        class="div-tooltip"
                        title="使用場所名称カナ、使用場所略称カナ、貸出先名称カナ、貸出先略称カナのいずれかに部分一致するものを検索します。"
                      ></div>
                    </div>
                    <div class="col-lg-5 dev-pc div-item-input">
                      <input
                        formControlName="kyotenKana"
                        id="kyotenKanaNm"
                        type="text"
                        maxlength="160"
                        name="kyotenKanaNm"
                        class="ng-untouched ng-pristine ng-valid dev-pc form-control"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="col-lg-3 div-column-resize-plus dev-pc div-item-title"
                      style="padding-right: 0px"
                    >
                      <label>郵便番号</label>
                      <div class="div-item-title-required"></div>
                      <div
                        class="div-tooltip"
                        title="ハイフンは入力しないでください。"
                      ></div>
                    </div>
                    <div class="col-lg-2 dev-pc div-item-input">
                      <input
                        formControlName="yubinNo"
                        id="yubinbangou"
                        type="text"
                        placeholder="0000000"
                        maxlength="7"
                        name="yubinNo"
                        class="ng-untouched ng-pristine ng-valid dev-pc form-control"
                      />
                    </div>
                  </div>
                  <div class="row">
                    <div
                      class="col-lg-3 div-column-resize-plus dev-pc div-item-title"
                      style="padding-right: 17px"
                    >
                      <label>住所</label>
                      <div class="div-item-title-required"></div>
                    </div>
                    <div
                      class="col-lg-7 align-items-baseline dev-pc div-item-input"
                    >
                      <input
                        id="jyusyo"
                        type="text"
                        formControlName="jusho"
                        maxlength="340"
                        name="jusho"
                        class="ng-untouched ng-pristine ng-valid dev-pc form-control"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div>
          <div>
            <div class="d-flex">
              <div>
                <button class="mx-2 dev-pc btn btn-lg btn-negative" (click)="onclickClear()">クリア</button>
              </div>
              <div class="me-auto"></div>
              <div id="pc-positive">
                <button type="submit" class="mx-2 dev-pc btn btn-lg btn-positive" (click)="onclickSearch()">検索</button>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="searchResult && searchResultCount" class="dev-pc div-card pc-min-width">
          <div class="dev-pc div-card-body">
            <div class="dev-pc div-card-title">
              <div class="row justify-content-between d-flex align-items-center">
                <div class="col-auto row d-flex align-items-center">
                  <span class="col-auto">検索結果</span>
                  <span class="col-auto gray cnt-font">全{{ searchResultCount }}件</span>
                </div>
                <div class="col-auto row d-flex align-items-center">
                  <div class="col-auto">
                    <span class="cnt-font">表示件数</span>
                  </div>
                  <div class="col-auto">
                    <form [formGroup]="searchForm">
                      <select
                        formControlName="linesPerPage"
                        id="paginationInput"
                        class="ng-untouched ng-pristine ng-valid dev-pc form-select"
                        (change)="onchangeLinesPerPage()"
                      >
                        <option *ngFor="let lines of linesPerPageList" [value]="lines">{{ lines }}</option>
                      </select>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <hr class="title-hr" />
            <div class="dev-pc div-card-body-sub"></div>
            <div class="dev-pc div-card-body-sub" *ngIf="searchResult">
              <div class="table-area" style="max-height: 375px; width: auto">
                <table
                  class="custom-table table table-striped table-hover"
                  style="width: auto; table-layout: fixed"
                >
                  <colgroup>
                    <col style="width: 120px" />
                    <col style="width: 210px" />
                    <col style="width: 210px" />
                    <col style="width: 45px" />
                    <col style="width: 500px" />
                    <col style="width: 120px" />
                    <col style="width: 210px" />
                    <col style="width: 60px" />
                  </colgroup>
                  <thead>
                    <tr>
                      <th rowspan="1" role="" sorted="">
                        拠点コード
                      </th>
                      <th rowspan="1" role="" sorted="">拠点名称</th>
                      <th rowspan="1">拠点名称カナ</th>
                      <th rowspan="1" role="" sorted="">種別</th>
                      <th rowspan="1" role="" sorted="">住所</th>
                      <th rowspan="1" role="" sorted="" style="white-space: nowrap">契約法人コード</th>
                      <th rowspan="1" role="" sorted="">契約法人名称</th>
                      <th
                        rowspan="1"
                        role=""
                        sorted="asc"
                        class="sticky-right"
                        style="right: 0px; z-index: 10;"
                      ></th>
                    </tr>
                  </thead>
                  <tbody
                    formarrayname="pageList"
                    class="ng-untouched ng-pristine ng-valid"
                  >
                    <tr *ngFor="let kyoten of searchResult">
                      <td class="text-truncate" role="" sorted="">
                        <span>{{ kyoten.kyoten_cd }}</span><br />
                      </td>
                      <td
                        class="text-truncate"
                        [attr.title]="kyoten.kyoten_long_nm"
                        role=""
                        sorted=""
                      >
                        <span>{{ kyoten.kyoten_nm }}</span><br />
                      </td>
                      <td
                        class="text-truncate"
                        [attr.title]="kyoten.kyoten_long_kana"
                        role=""
                        sorted=""
                      >
                        <span>{{ kyoten.kyoten_kana }}</span><br />
                      </td>
                      <td class="text-truncate" role="" sorted="">
                        <span>{{ kyoten.shubetsu == "s" ? '使用' : '貸出' }}</span><br />
                      </td>
                      <td
                        class="text-truncate"
                        [attr.title]="'〒' + kyoten.yubin_no + ' ' + kyoten.kyoten_jusho"
                        role=""
                        sorted=""
                      >
                        <span>
                          〒{{ kyoten.yubin_no }} {{ kyoten.kyoten_jusho}}</span
                        ><br />
                      </td>
                      <td
                        class="text-truncate"
                        [attr.title]="kyoten.x_keiyaku_hojin_cd"
                        role=""
                        sorted=""
                      >
                        <span>{{ kyoten.x_keiyaku_hojin_cd }}</span><br />
                      </td>
                      <td
                        class="text-truncate"
                        [attr.title]="kyoten.keiyaku_hojin_nm"
                        role=""
                        sorted=""
                      >
                        <span>{{ kyoten.keiyaku_hojin_nm }}</span><br />
                      </td>
                      <td
                        class="text-center sticky-right"
                        role=""
                        sorted="asc"
                        style="right: 0px; z-index: 10;"
                      >
                        <button
                          class="dev-pc btn btn-sm btn-standard"
                          style="white-space: nowrap;"
                          (click)="onclickSelect(kyoten)"
                        >
                          選択
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="d-flex justify-content-center pagination">
              <switch-page
                [currentPage]="searchForm.controls.pageTo.value"
                [linesPerPage]="searchForm.controls.linesPerPage.value"
                [totalCount]="searchResultCount"
                (switchPage)="onSwitchPage($event)"
                ></switch-page>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

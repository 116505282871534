import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';

const logger = environment.logger;

export const authGuard: CanActivateFn = (_route, state) => {
  const authService = inject(AuthService);

  return authService.isAuthenticated$.pipe(
    tap((isLoggedIn) => {
      logger.log("authGuard: isLoggedIn", isLoggedIn);
      if (!isLoggedIn) {
        authService.loginWithRedirect({
          appState: { target: state.url },
        });
      }
    })
  );
};

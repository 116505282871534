<div id="dialogModal_btn" class="modal is-dialog" [attr.style]="isShow ? 'display: block;' : ''">
  <div class="modal-inner">
    <div class="modal-content">
      <div class="modal-header" style="height: 49px;touch-action: none; -webkit-user-drag: none; -webkit-tap-highlight-color: transparent; user-select: none;">
        <h4 class="modal-title">{{ modalTitle }}</h4>
      </div>
      <!--モーダルコンテンツ-->
      <div class="modal-body">
        <ng-content></ng-content>
      </div>
      <div class="bg-card modal-footer" style="justify-content: center;align-items: center;width: 100%;height: 49px;padding-top: 0;padding-bottom: 0;background: #FCFAF7;display: block;">
        <div class="d-flex" style="margin-top: 0;margin-bottom: 0;">
          <div class="me-auto px-1">
            <div id="pc-footer-negative">
              <button *ngIf="leftLabel" class="mx-2 dev-pc btn btn-lg btn-negative modalClose" (click)="onclickLeft()">{{ leftLabel }}</button>
            </div>
          </div>
          <div class="px-1">
            <div id="pc-footer-other">
              <button class="mx-2 dev-pc btn btn-lg btn-positive" [disabled]="rightButtonDisabled" (click)="onclickRight()">{{ rightLabel }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { Component } from '@angular/core';

@Component({
  selector: 'app-servererror',
  template: `
    <div>現在サービスを利用できません。</div>
  `,
  styles: ['div { margin-top: 20px; }'],
})
export class ServiceUnavailableComponent {}

import { Component } from '@angular/core';
import { interval } from 'rxjs';

@Component({
  selector: 'loading-component',
  templateUrl: './loading.component.html',
  // template: '<div>読み込み中{{ loadingDot }}</div>',
  styleUrls: ['./loading.component.scss'],
  // styles: [
  //   `
  //     div {
  //       width: 100%;
  //       height: 100%;
  //       display: flex;
  //       font-size: 2rem;
  //       color: white;
  //       justify-content: center;
  //       align-items: center;
  //     }
  //   `,
  // ],
})
export class LoadingComponent {
  loadingDot: '' | '.' | '..' | '...' = '.';

  ngOnInit(): void {
    interval(700).subscribe(() => {
      if (this.loadingDot === '...') {
        this.loadingDot = '';
      } else {
        this.loadingDot = (this.loadingDot + '.') as '' | '.' | '..' | '...';
      }
    });
  }
}

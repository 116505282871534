<ul class="pagination">
  <li class="page-item" [class.disabled]="!hasFirstPage()">
    <a
      aria-label="First"
      class="page-link"
      tabindex="-1"
      [attr.aria-disabled]="!hasFirstPage()"
      (click)="first()"
    >
      <i class="bi bi-chevron-bar-left"></i>
    </a>
  </li>
  <li class="page-item" [class.disabled]="!hasPreviousPage()">
    <a
      aria-label="Previous"
      class="page-link"
      tabindex="-1"
      [attr.aria-disabled]="!hasPreviousPage()"
      (click)="previous()"
    >
      <i class="bi bi-chevron-left"></i>
    </a>
  </li>
  <div class="d-flex flex-nowrap px-2 align-items-center">
    <select
      id="paginationInput"
      class="ng-untouched ng-pristine ng-valid dev-pc form-select"
      [(ngModel)]="currentPage"
      (change)="pageTo()"
    >
      <option *ngFor="let lines of pageList()" [value]="lines">{{lines}}</option>
    </select>
    <span id="paginationDescription" class="col-form-label text-nowrap">/{{ totalPage() }}</span>
  </div>
  <li class="page-item" [class.disabled]="!hasNextPage()">
    <a
      aria-label="Next"
      class="page-link"
      tabindex="-1"
      [attr.aria-disabled]="!hasNextPage()"
      (click)="next()"
    >
      <i class="bi bi-chevron-right"></i>
    </a>
  </li>
  <li class="page-item" [class.disabled]="!hasLastPage()">
    <a
      aria-label="Last"
      class="page-link"
      tabindex="-1"
      [attr.aria-disabled]="!hasLastPage()"
      (click)="last()"
    >
      <i class="bi bi-chevron-bar-right"></i>
    </a>
  </li>
</ul>

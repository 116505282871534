import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { TopPageRoutingModule } from './toppage-routing.module';
import { TopPageComponent } from './toppage.component/toppage.component';
import { TopPageService } from './toppage.component/toppage.service';
import { LoadingModule } from 'projects/mamezo/src/app/common/loading/loading.module';
import { ConfirmModalComponent } from 'projects/mamezo/src/app/page/toppage/modal.component/confirm-modal/confirm-modal.component';
import { SimpleModalComponent } from './modal.component/simple-modal/simple-modal.component';
import { HojinSearchModalComponent } from './modal.component/hojin-search-modal/hojin-search-modal.component';
import { KyotenSearchModalComponent } from './modal.component/kyoten-search-modal/kyoten-search-modal.component';
import { SwitchPageComponent } from './pager.component/switch-page.component/switch-page.component';
import { SearchFormComponent } from './search-form.component/search-form.component';
import { SearchResultComponent } from './search-result.component/search-result.component';
import { MamezoMessageComponent } from './message.component/message.component';

@NgModule({
  declarations: [
    TopPageComponent,
    ConfirmModalComponent,
    SimpleModalComponent,
    HojinSearchModalComponent,
    KyotenSearchModalComponent,
    SwitchPageComponent,
    SearchFormComponent,
    SearchResultComponent,
    MamezoMessageComponent,
  ],
  imports: [TopPageRoutingModule, CommonModule, LoadingModule, ReactiveFormsModule, FormsModule],
  providers: [TopPageService],
})
export class TopPageModule {}

import { Component, Input } from '@angular/core';
import { MamezoMessage } from '../toppage-types';

@Component({
  selector: 'mamezo-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
})
export class MamezoMessageComponent {
  @Input() messageData?: MamezoMessage = undefined;

  getCodeString() {
    return this.messageData?.code ? `[${this.messageData?.code}]` : "";
  }
}

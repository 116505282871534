import { Injectable } from '@angular/core';
import { AppState, LogoutOptions, RedirectLoginOptions } from '@auth0/auth0-angular';
import { Observable, of } from 'rxjs';
import { environment as env } from '../../environments/environment';

const logger = env.logger;

@Injectable({ providedIn: 'root' })
export class MamezoAuthService {
  isAuthenticated$: Observable<boolean>; // 要 定義
  user$: Observable<import('@auth0/auth0-spa-js').User | null | undefined>;
  constructor() {
    logger.log('MamezoAuthService ');
    this.isAuthenticated$ = of(true);
    this.user$ = of({
      ['https://schemas.jpr.jp/username']: 'dummy',
    });
  }
  loginWithRedirect(_options?: RedirectLoginOptions<AppState> | undefined) {
    return;
  }
  logout(_options?: LogoutOptions) {
    return;
  }
  getAccessTokenSilently(
    _options: import('@auth0/auth0-spa-js').GetTokenSilentlyOptions & {
      detailedResponse: true;
    }
  ): Observable<import('@auth0/auth0-spa-js').GetTokenSilentlyVerboseResponse> {
    console.warn("!!! 開発環境の構成で起動中 !!!");
    return of({
      id_token: env.TMP_TOKEN || '',
      access_token: env.TMP_TOKEN || '',
      expires_in: 0,
    });
  }
}

import { Component } from '@angular/core';

@Component({
  selector: 'app-notfound',
  template: `
    <div>ページが見つかりませんでした。</div>
  `,
  styles: ['div { margin-top: 20px; }'],
})
export class NotFoundComponent {}

import { Component, Input } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'confirm-modal',
  templateUrl: './confirm-modal.component.html',
})
export class ConfirmModalComponent {
  @Input() modalTitle : string = "確認";
  @Input() leftLabel?: string;
  @Input() rightLabel?: string;
  @Input() isShow : boolean = false;
  @Input() rightButtonDisabled : boolean = false;
  @Output() clickLeft = new EventEmitter<string>();
  @Output() clickRight = new EventEmitter<string>();

  onclickLeft() {
    this.clickLeft.emit();
  }
  onclickRight() {
    this.clickRight.emit();
  }
}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ComponentInteractionService {
  private subject = new Subject<any>();

  event$ = this.subject.asObservable();

  publish(change: any) {
    this.subject.next(change);
  }
}

<!-- <div class="app-wrapper" *ngIf="token !== null && (isAuthenticated || isError)">
  <div
    id="side-menu-background"
    class="mobile-hidden pc-hidden"
    (click)="sideMenuToggleOpenClose()"
  ></div> -->
<!-- 
  <header>
    <button
    *ngIf="isShowSideMenuToggleButton"
    style="margin-top: 10px; margin-left: 10px"
    (click)="sideMenuToggleOpenClose()"
    >
    三
  </button>
</header>
-->
<div class="content">
  <div style="position: absolute; top: 10px; left: 180px; z-index: 1031;">{{ envName }}</div>
  <router-outlet></router-outlet>
</div>
<!-- <div style="min-height: 20px; background-color: #ddd"></div>
</div> -->

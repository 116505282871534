import { Component } from '@angular/core';

@Component({
  selector: 'app-unauthorized',
  template: `
    <div>アクセス権がありません。</div>
  `,
  styles: ['div { margin-top: 20px; }'],
})
export class UnauthorizedComponent {}
